import React, { useState, useEffect } from "react";
// import logo from '../goldWB.png'
import { Modal, Button, Col, Row, Form } from 'react-bootstrap';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import QRCode from 'qrcode';
import MuiInput from '@mui/material/Input';
import "../App.css";
import pic1 from '../logos/info1.jpg'
import pic2 from '../logos/info2.jpg'

const Info = (props) => {

    const font = props.font;
    const content = props.content;
    const theme = props.theme;
    const [flag, setFlag] = useState(false);

    const control = useAnimation();
    const [ref, inView] = useInView()

    const boxVariant1 = {
        visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
        hidden: { opacity: 0, scale: 0 }
    };

    const boxVariant2 = {
        visible: { opacity: 1, scale: 1, transition: { duration: 0.8 } },
        hidden: { opacity: 0, scale: 0 }
    };

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);

    return (
        <section className="text-center">
            <div className="row p-4">
                <div className="column container ">
                    <p className="text-left container ">
                        <img
                            display="flex"
                            src={pic1}
                            alt="Profile"
                            style={{ width: "100%", maxheight: "100%", borderTopLeftRadius: 100 }}
                        ></img>
                        <h2 className={`${font} mt-4`}>{content.Miss1}</h2>
                        <h4 className={`${font} mt-4 text-start`}>{content.Miss2}</h4>
                        <h4 className={`${font} text-start`}>{content.Miss3}</h4>
                        <h4 className={`${font} text-start`}>{content.Miss4}</h4>

                    </p>
                </div>
                <div className="column">
                    <p className="container text-left">
                        <h2 className={`${font} mt-3`}>{content.Who1}</h2>
                        <h4 className={`${font} mt-4 text-start`}>{content.Who2}</h4>
                        <h4 className={`${font} text-start`}>{content.Who3}</h4>
                        <h4 className={`${font} mb-4 text-start`}>{content.Who4}</h4>
                        <img
                            display="flex"
                            src={pic2}
                            alt="Profile"
                            style={{ width: "100%", maxheight: "100%", borderBottomRightRadius: 100 }}
                            className="mt-5"
                        ></img>
                    </p>

                </div>
            </div>
        </section >
    );
};

export default Info;