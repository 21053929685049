import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import termsEn from '../pdfs/TermsOfServiceEn.pdf';
import termsGr from '../pdfs/TermsOfServiceGr.pdf';
import policy from '../pdfs/PrivacyPolicyEn.pdf';
import { Row, Col } from 'react-bootstrap';

const Footer = (props) => {

    const language = props.language;
    const terms = language === 'en' ? termsGr : termsEn ;
    const content = props.content;

    return (
        <footer className="bg-black text-center py-5">
            <div className="container px-5">
                <div className="text-white-50 small">
                    <div className="mb-2">{content.Footer}</div>
                    <div className="mb-2">{content.Footer2}</div>
                    <a className="p-2" href='https://facebook.com/gostatim' target='_blank'>
                        <FaFacebookF size={22}/>
                    </a>
                    <a className="p-2" href='https://www.instagram.com/gostatim_' target='_blank'>
                        <FaInstagram size={25}/>
                    </a>
                    <a className="p-2" href='https://www.linkedin.com/company/gostatim/' target='_blank'>
                        <FaLinkedinIn size={25}/>
                    </a>
                </div>
                <div>
                    <p></p>
                </div>
                <div className="text-white-50 small">
                    <Row>
                        <Col/>
                        <Col>
                            <a target='_blank' href={terms} >{content.Footer3}</a>
                        </Col>
                        <Col>
                            <a target='_blank' href={policy}>{content.Footer4}</a>
                        </Col>
                        <Col/>
                    </Row>
                </div>
            </div>
        </footer>
    );
};

export default Footer;