import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Faq from "react-faq-component";
import { BsFillPlusSquareFill } from "react-icons/bs"; 
import { AiOutlineMinusCircle } from "react-icons/ai";
import "../App.css";



const FAQ = (props) => {

    const content = props.content;
    const font = props.font;

    const theme = props.theme === "dark" ? "bg-dark text-white" : "bg-light text-dark";

    const styles = {
        bgColor: props.theme,
        titleTextColor: props.theme === "dark" ? "light" : "dark",
        rowTitleColor: props.theme === "dark" ? "light" : "dark",
        rowContentColor: props.theme === "dark" ? "light" : "dark",
        arrowColor: props.theme === "dark" ? "light" : "dark",
        expandIconColor: props.theme === "dark" ? "#fff" : "dark",
    };

    const config = {
        animate: true,
        arrowIcon: "+",
        openOnload: -1,
        expandIcon: "+",
        collapseIcon: "-",
    };

    const data = {
        content : props.content,
        rows: [
            {
                title: <h3 className={`${font}`}>{content.Faq1}</h3>,
                content: <p className={`${font} lead`}>{content.Faq2}
                    <br /><ul>
                        <li>{content.Faq23}</li>
                        <li>{content.Faq24}</li>
                        <li>{content.Faq25}</li>
                        <li>{content.Faq26}</li>
                    </ul></p>,
            },
            {
                title: <h3 className={`${font}`}>{content.Faq3}</h3>,
                content:
                    <p className={`${font} lead`}>{content.Faq32}</p>,
            },
            {
                title: <h3 className={`${font}`}>{content.Faq4}</h3>,
                content: <p className={`${font} lead`}>{content.Faq42}</p>,
            },
            {
                title: <h3 className={`${font}`}>{content.Faq5}</h3>,
                content: <p className={`${font} lead`}>{content.Faq52}</p>,
            },
            {
                title: <h3 className={`${font}`}>{content.Faq7}</h3>,
                content: <p className={`${font} lead`}>{content.Faq72}</p>,
            },
            {
                title: <h3 className={`${font}`}>{content.Faq8}</h3>,
                content: <p className={`${font} lead`}>{content.Faq82}</p>,
            },
            {
                title: <h3 className={`${font}`}>{content.Faq9}</h3>,
                content: <p className={`${font} lead`}>{content.Faq92}</p>,
            },
            {
                title: <h3 className={`${font}`}>{content.Faq10}</h3>,
                content: <p className={`${font} lead`}>{content.Faq102}</p>,
            },
            {
                title: <h3 className={`${font}`}>{content.Faq11}</h3>,
                content: <p className={`${font} lead`}>{content.Faq112}
                    <br />
                    {content.Faq1112}
                    <br />
                    <ul>
                        <li>{content.Faq113}</li>
                        <li>{content.Faq114}</li>
                        <li>{content.Faq115}</li>
                        <li>{content.Faq116}</li>
                        <li>{content.Faq117}</li>
                        <li>{content.Faq118}</li>
                        <li>{content.Faq119}</li>
                    </ul>
                    <br />
                    {content.Faq1110}
                    {content.Faq1111}
                    <br />
                    
                    {content.Faq1114}
                    {content.Faq1115}
                    <br />
                    
                    {content.Faq1116}
                    {content.Faq1117}
                    <br /><br />
                    
                    {content.Faq1118}
                    {content.Faq1119}
                    {content.Faq1120}
                </p>,
            }
        ],
    };

    return (
        <section className="">
            <h3 className={`text-center display-4 lh-1 mb-5 mt-0 ${font}`}>FAQ</h3>
            <div className={`${theme} container`}>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </section>
    );
}


export default FAQ;